* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.mainContainer {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: url(./images/artistic-collage-wallpaper-3840x2160_54.jpg);
  background-position: center;
  background-size: cover;
}

.Header {
  background-color: rgb(252, 252, 252);
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 5px solid black;
}

.Header nav a {
  text-decoration: none;
  position: relative;
  margin: auto;
  padding: 0px 30px;
  font-size: xx-large;
  color: black;
}

.Header nav a img {
  height: 5rem;
  margin: 25px 50px;
}

.Header nav a:after {
  content: "";
  position: absolute;
  bottom: 1rem;
  right: 0px;
  width: 0%;
  height: 1px;
  background: black;
  border-radius: 3px;
  transition: width 0.5s;
}

.Header nav a:hover:after {
  width: 100%;
}

.Header nav a img:active {
  transform: scale(0.925);
}
.twitchContainer {
  border-radius: 5rem;
  margin: 10rem;
  height: 1080px;
  width: 1920px;
}

#twitchVod {
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  border: 1rem solid white;
}

#profilePic {
  margin: auto;
  padding: 10px;
  background-color: white;
  border: 5px solid black;
  border-radius: 100%;
  height: 25%;
}

.sections {
  background-color: white;
  font-size: xx-large;
  margin: 2rem 10rem;
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
}

.footer {
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
}

.footerCol {
  display: flex;
  flex-direction: column;
  color: black;
  font-size: 1.5rem;
  margin: 4.5rem;
}

.footerCol a {
  color: rgb(60, 60, 60);
  text-decoration: none;
  padding: 0.5rem 0;
  font-size: 2rem;
  position: relative;
}

.footer div a:after {
  content: "";
  position: absolute;
  bottom: 0.5rem;
  left: 0px;
  width: 0%;
  height: 1px;
  background: black;
  border-radius: 3px;
  transition: width 0.5s;
}

.footer div a:hover:after {
  width: 100%;
}

@media only screen and (max-width: 1920px) {
  .Header {
    height: 7rem;
  }

  .Header nav a img {
    height: 3rem;
    width: 3rem;
    margin: 25px 40px;
  }

  .twitchContainer {
    border-radius: 5rem;
    margin: auto;
    height: 720px;
    width: 1280px;
  }

  #twitchVod {
    margin: 2rem 0;

    border-radius: 1rem;
    border: 0.75rem solid white;
    width: 1200px;
  }

  #profilePic {
    margin-top: 4rem;
    padding: 10px;
    background-color: white;
    border: 5px solid black;
    border-radius: 100%;
    width: 30%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .sections {
    background-color: white;
    font-size: x-large;
    margin: 1rem 5rem;
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
  }
}

@media only screen and (max-width: 1200px) {
  .Header nav a {
    text-decoration: none;
    position: relative;
    margin: auto;
    padding: 0px 10px;
    font-size: xx-large;
    color: black;
  }

  .Header nav a img {
    height: 2.5rem;
    width: 2.5rem;
    margin: 25px 50px;
  }

  .twitchContainer {
    border-radius: 5rem;
    margin: auto;
    height: 580px;
    width: 1000px;
  }

  #twitchVod {
    margin: 2rem 0;

    border-radius: 1rem;
    border: 0.75rem solid white;
    width: 1200px;
  }

  #profilePic {
    margin-top: 4rem;
    padding: 10px;
    background-color: white;
    border: 5px solid black;
    border-radius: 100%;
    width: 35%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .sections {
    background-color: white;
    font-size: large;
    margin: 1rem 5rem;
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
  }

  .footerCol {
    font-size: 1rem;
    margin: 2.5rem;
  }

  .footerCol a {
    padding: 0.5rem 0;
    font-size: 1.1rem;
  }
}



@media only screen and (max-width: 1024px) {
  .Header nav a img {
    height: 2.5rem;
    width: 2.5rem;
    margin: 25px 25px;
  }

  .twitchContainer {
    border-radius: 5rem;
    margin: auto;
    height: 500px;
    width: 875px;
  }

  #twitchVod {
    margin: 2rem 0;

    border-radius: 1rem;
    border: 0.6rem solid white;
    width: 1200px;
  }

  #profilePic {
    margin-top: 3rem;
    padding: 10px;
    background-color: white;
    border: 5px solid black;
    border-radius: 100%;
    width: 30%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .sections {
    background-color: white;
    font-size: large;
    margin: 1rem 5rem;
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
  }
}

@media only screen and (max-width: 900px) {
  .twitchContainer {
    border-radius: 5rem;
    margin: auto;
    height: 420px;
    width: 720px;
  }

  #twitchVod {
    margin: 2rem 0;

    border-radius: 1rem;
    border: 0.6rem solid white;
    width: 1200px;
  }

  #profilePic {
    margin-top: 4rem;
    padding: 10px;
    background-color: white;
    border: 5px solid black;
    border-radius: 100%;
    width: 30%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .sections {
    background-color: white;
    font-size: large;
    margin: 1rem 5rem;
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
  }

  .footerCol {
    font-size: .7rem;
    margin: 2.5rem;
  }

  .footerCol a {
    padding: 0.5rem 0;
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 768px) {
  .Header {
    height: 5.5rem;
  }

  .Header nav a img {
    height: 2.25rem;
    width: 2.25rem;
    margin: 20px 20px;
  }

  .twitchContainer {
    border-radius: 5rem;
    margin: auto;
    height: 400px;
    width: 650px;
  }

  #twitchVod {
    margin: 2rem 0;
    border-radius: 1rem;
    width: 1200px;
  }

  #profilePic {
    margin-top: 4rem;
    padding: 10px;
    background-color: white;
    border: 5px solid black;
    border-radius: 100%;
    width: 35%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .sections {
    background-color: white;
    font-size: large;
    margin: 1rem 5rem;
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
  }

  .footerCol {
    font-size: .75rem;
    margin: 1.5rem;
  }

  .footerCol a {
    padding: 0.5rem 0;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .Header nav a {
    padding: 0px 1rem;
  }

  .Header nav a img {
    height: 2rem;
    width: 2rem;
    margin: 20px 10px;
  }

  .twitchContainer {
    margin: auto;
    height: 310px;
    width: 525px;
  }

  #profilePic {
    margin-top: 4rem;
    padding: 10px;
    background-color: white;
    border: 5px solid black;
    border-radius: 100%;
    width: 35%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .sections {
    background-color: white;
    font-size: medium;
    margin: 1rem 4rem;
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
  }

  .footerCol {
    font-size: .6rem;
    margin: 1rem;
  }

  .footerCol a {
    padding: 0.5rem 0;
    font-size: .9rem;
  }
}

@media only screen and (max-width: 480px) {
  .Header {
    height: 4rem;
  }

  .Header nav a {
    text-decoration: none;
    position: relative;
    margin: auto;
    padding: 0px 10px;
    font-size: xx-large;
    color: black;
  }

  .Header nav a img {
    height: 1.75rem;
    width: 1.75rem;
    margin: 20px 10px;
  }

  .twitchContainer {
    border-radius: 5rem;
    margin: auto;
    height: 250px;
    width: 440px;
  }

  #twitchVod {
    margin: 2rem 0;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    border: 0.5rem solid white;
  }

  #profilePic {
    margin-top: 4rem;
    padding: 10px;
    background-color: white;
    border: 5px solid black;
    border-radius: 100%;
    width: 35%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .sections {
    background-color: white;
    font-size: medium;
    margin: 1rem 3rem;
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
  }

  .footerCol {
    font-size: .55rem;
    margin: .45rem;
  }

  .footerCol a {
    padding: 0.35rem 0;
    font-size: .85rem;
  }
}

@media only screen and (max-width: 320px) {
  .Header {
    background-color: rgb(252, 252, 252);
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 5px solid black;
  }

  .Header nav a img {
    height: 1.25rem;
    width: 1.25rem;
    margin: 5px 0px;
  }

  .twitchContainer {
    border-radius: 5rem;
    margin: auto;
    height: 170px;
    width: 290px;
  }

  #twitchVod {
    margin: 2rem 0;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    border: 0.4rem solid white;
  }

  #profilePic {
    margin-top: 4rem;
    padding: 10px;
    background-color: white;
    border: 3px solid black;
    border-radius: 100%;
    width: 40%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .sections {
    background-color: white;
    font-size: 11px;
    margin: 1rem;
    text-align: center;
    padding: 1rem;
    border-radius: 2rem;
  }

  .footerCol {
    font-size: .35rem;
    margin: .35rem;
  }

  .footerCol a {
    padding: 0.1rem 0;
    font-size: .55rem;
  }
}
